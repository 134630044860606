.root {
  width: 75%;
  max-height: calc(150vh - 160px);
  margin: 20px 0px;
  @media (max-width: 1200px) {
    width: 100%;
    margin-bottom: 130px;

    .container {
      flex-direction: column;
    }
  }
  .container {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 15px;
    -webkit-box-shadow: 0px 4px 8px 0px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 4px 8px 0px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 4px 8px 0px rgba(34, 60, 80, 0.2);

    .chatContainer {
      display: flex;
      flex-direction: column;
      padding: 20px;
      width: 100%;
      height: 120vh;
      position: relative;
      background-color: #fff;

      .user {
        display: flex;
        align-items: center;
        gap: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
        border-top: 1px solid rgba(0, 0, 0, 0.078);
        border-bottom: 1px solid rgba(0, 0, 0, 0.078);
        width: 100%;
        height: 100px;
        z-index: 4444;
        .avatar {
          width: 50px;
          width: 50px;
        }
        .details {
          display: flex;
          align-items: center;
          gap: 10px;
          h4 {
            text-transform: capitalize;
            color: #1d3a5f;
          }
          span {
            border-radius: 50%;
            background-color: rgb(3, 164, 3);
            width: 10px;
            height: 10px;
            padding: 5px;
          }
        }
      }
      .chat {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 20px 0px;
        padding: 10px;
        gap: 20px;
        overflow: auto;
        height: 100%;

        &::-webkit-scrollbar {
          width: 10px;
          background-color: #f5f5f5;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          background-color: #1877f2;
        }
        .message {
          display: flex;
          width: 100%;
          gap: 10px;
          .avatar {
            width: 40px;
            height: 40px;
          }
          .content {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            max-width: 50%;
            background-color: #ebebeb1e;
            padding: 10px;
            border-radius: 15px;
            -webkit-box-shadow: 0px 4px 8px 0px rgba(34, 60, 80, 0.2);
            -moz-box-shadow: 0px 4px 8px 0px rgba(34, 60, 80, 0.2);
            box-shadow: 0px 4px 8px 0px rgba(34, 60, 80, 0.2);
            gap: 10px;
            .details {
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              width: 100%;
              justify-content: space-between;
              .text {
                font-size: 16px;
                word-break: break-all;
              }
              .date {
                position: relative;
                top: 5px;
                right: 0;

                color: rgb(145, 164, 191);
                margin-left: 10px;
                font-size: 12px;
              }
            }

            img {
              border-radius: 15px;
              width: 100%;
              object-fit: contain;
              cursor: pointer;
            }
          }
        }
      }
      .messageInput {
        display: flex;
        align-items: center;
        height: 50px;
        border-top: 1px solid rgba(0, 0, 0, 0.078);
        .input {
          display: flex;
          align-items: center;
          padding: 10px 20px 10px 20px;
          margin-top: 15px;
          width: 100%;
          height: 100%;
          border-radius: 20px;
          background-color: #ebebeb8c;
          gap: 10px;
          input {
            height: 100%;
            width: 100%;
            border: none;
            outline: none;
            background-color: #ebebeb50;
            font-size: 16px;

            &::placeholder {
              color: rgb(145, 164, 191);
            }
          }
          label {
            display: flex;
            border-radius: 50%;
            padding: 10px;
            background-color: #c4c4c4;
            color: white;
            cursor: pointer;
            transition: 0.3s ease-in-out;
            svg {
              width: 15px;
              height: 15px;
            }

            &:hover {
              background-color: #0954b6;
            }
          }
          span {
            display: flex;
            border-radius: 50%;
            padding: 10px;
            background-color: #1877f2;
            color: white;
            cursor: pointer;
            transition: 0.3s ease-in-out;
            svg {
              width: 15px;
              height: 15px;
            }

            &:hover {
              background-color: #0954b6;
            }
          }
        }
      }
    }
  }
}
